/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2021-01-12 16:32:06
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-14 09:50:44
 */
import { wVerifyNum, wVerifyDeciTwo } from '@/plugins/verifier'
import HandleData from '@/service/Agent'
export default {
  name: 'AgentCombo',
  data () {
    return {
      WComboEditShow: false,
      showCancel: true,
      pickerBeginDateBefore: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      form: {
        id: '',
        storage_cnt: '',
        expire_date: '',
        pay_money: ''
      },
      rules: {
        storage_cnt: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写数据存储额度')
            }
            if (!wVerifyNum(val)) {
              res('请填写正整数')
            }
            if (val.length > 12) {
              res('数据存储额度不能超过12位')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }],
        pay_money: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写已付金额')
            }
            if (!wVerifyDeciTwo(val)) {
              res('请填写正确的金额类型')
            }
            if (val > 100000000000) {
              res('售卖价格不能超过1000亿')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }],
        expire_date: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写生效时间')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }]
      }
    }
  },
  methods: {
    close () {
      this.$refs.form.clearValidate()
      this.updatedForm({
        id: '',
        storage_cnt: '',
        expire_date: '',
        pay_money: ''
      })
      if (this.showCancel) {
        this.$wToast.warning('已取消修改')
      }
    },
    async updatedForm (payload) {
      for (const props in payload) {
        await this.$set(this.form, props, payload[props])
      }
    },
    hide () {
      this.WComboEditShow = false
    },
    show () {
      this.WComboEditShow = true
    },
    submit () {
      this.$refs.form.validate(async v => {
        if (v) {
          const newPayload = new HandleData(this.form).editAgentCombo()
          console.log(newPayload)
          const data = await this.$wPost('/admin/agent/update_combo.do', newPayload)
          if (data === true) {
            this.$wToast.success('修改套餐成功')
            this.showCancel = false
            this.hide()
            this.$emit('add')
          }
          const timer = setTimeout(() => {
            clearTimeout(timer)
            this.showCancel = true
          }, 500)
        }
      })
      this.$refs.submitButton.updatedLoading(false)
    }
  },
  props: {
    editName: {
      type: String
    }
  }
}
