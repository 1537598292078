/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-22 14:40:06
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-15 09:34:25
 */
class HandleData {
  constructor (payload) {
    this.payload = payload
  }

  agentMax () {
    const newPayload = {
      id: this.payload.agent_id,
      max_custom_num: Number(this.payload.max_custom_num)
    }
    return newPayload
  }

  add () {
    const newPayload = {
      agent_name: this.payload.agent_name
    }
    const userList = this.payload.user_list.map(v => {
      if (v.id) {
        return v.id
      }
    })

    newPayload.user_list = userList
    return newPayload
  }

  edit () {
    const newPayload = {
      agent_name: this.payload.agent_name,
      id: this.payload.agent_id
    }
    const userList = this.payload.user_list.map(v => {
      if (v.id) {
        return v.id
      }
    })

    newPayload.user_list = userList
    return newPayload
  }

  editAgentCombo () {
    const newPayload = {
      id: this.payload.id,
      storage_cnt: this.payload.storage_cnt,
      expire_date: this.payload.expire_date,
      pay_money: Number(this.payload.pay_money) * 100
    }
    return newPayload
  }
}

export default HandleData
